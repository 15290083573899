<template>
    <div class="w-100 pb-5 pt-lg--10">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row justify-content-center my-5 content">
                    <div class="col-12">
                        <div class="col-12">
                            <div class="card e_card mb-5">
                                <div class="card-body p-0">
                                    <h1 class="text-grey-900 fw-700 font-lg mb-2 d-block">Welcome to the Cloud Services</h1>
                                    <p class="p-0 mb-4 text-grey-900">Explore our multimedia e-learning materials stored in
                                        the cloud.</p>
                                    <router-link to="/register" class="btn btn-success e_btn">Register</router-link>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-6 col-sm-12 px-2" v-for="(item, index) in cardData"
                                    :key="index">
                                    <div class="card e_card_feature mb-3">
                                        <div class="card-body p-0">
                                            <img :src="item.Icon" />
                                            <h2 class="card-title fw-600 mt-4 mb-3">{{ item.Title }}</h2>
                                            <div class="pt-lg-3">
                                                <p class="card-text" v-if="item.showFullDescription">{{ item.Description }}
                                                </p>
                                                <p class="card-text" v-else>
                                                    {{ item.Description.substring(0, 70) }}...
                                                    <span v-if="item.Description.length > 70" class="read-more"
                                                        @click="toggleDescription(item)">
                                                        Read More
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style>
.e_card {
    border-radius: 24px;
    background: #FBAF03;
    padding: 40px 32px;
}

.e_card_feature {
    border-radius: 20px;
    background: #F6F7FC;
    padding: 24px;
}

.e_btn {
    color: #fff;
    background-color: #1E6F5C;
    border-color: #1E6F5C;
    border-radius: 12px;
    padding: 8px 16px;
}

.card-text {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-self: stretch;
    font-size: 16px;
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.18px;
}

.content {
    margin-top: 48px !important;
}

.read-more {
    cursor: pointer;
    color: blue;
    font-weight: 500;
    margin-top: 4px;
}

/* Media query for tablet and mobile screens */
@media (max-width: 991.98px) {
    .content {
        margin-top: 96px !important;
    }
}
</style>

<script>
export default {
    data() {
        return {
            cardData: [
                {
                    Icon: "/images/ic/ic_cl_vp.svg",
                    Title: "Video and Podcast",
                    Description:
                        "Access and store Recorded Videos in MP4 format and Podcasts in MP3 format. Our cloud service provides storage and distribution for Recorded Videos and Podcasts. Distribution access rights are managed through Elingway Cloud.",
                    showFullDescription: false
                },
                {
                    Icon: "/images/ic/ic_cl_ap.svg",
                    Title: "Animated Presentation",
                    Description:
                        "Interactive gamification-themed Animated Presentations stored in HTML format. Engage with dynamic content designed to enhance your learning experience.",
                    showFullDescription: false
                },
                {
                    Icon: "/images/ic/ic_cl_sb.svg",
                    Title: "SmartBook",
                    Description:
                        "Interactive ebook experience with SmartBooks in EPUB format. Explore educational content in an engaging and interactive way.",
                    showFullDescription: false
                },
            ],
        };
    },
    methods: {
        toggleDescription(item) {
            item.showFullDescription = !item.showFullDescription;
        }
    }
};
</script>
